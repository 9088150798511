import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import { headerLogo, LineArrow } from 'assets/image';
import LazyImage from 'components/Common/LazyImage';
import useMemoCookies from 'hooks/Common/useMemoCookies';
import usePageHistory from 'hooks/Common/usePageHistory';

export function PaymentNoticePop({
  close,
  cookieName,
}: {
  close: Dispatch<SetStateAction<boolean>>;
  cookieName: string;
}) {
  const memoCookies = useMemoCookies();

  const [currentCheckDay, setCurrentCheckDay] = useState<number | null>(null);

  const { history } = usePageHistory();

  const handleGoPlan = () => {
    history.push('/plan/explain');
    close(false);
  };

  const handleCloseToday = () => {
    if (!currentCheckDay) {
      close(false);
      return;
    }

    memoCookies.set(cookieName, cookieName, {
      path: '/',
      expires: DateTime.now().plus({ days: 1 }).toJSDate(),
      maxAge: 24 * 60 * 60 * currentCheckDay,
      sameSite: 'lax',
    });
    close(false);
  };

  return (
    <div className="absolute top-[50vh] -translate-y-1/2 left-1/2 -translate-x-1/2 z-[55] bg-white shadow-mypage">
      <div className=" bg-release_paymentpop-bg-black  bg-cover text-white w-[850px] h-[556px] grid grid-cols-2 ">
        <div>
          <div className="absolute top-[84px] left-[70px]">
            <LazyImage src={headerLogo} width={216} alt="larchive-logo" />
          </div>
          <div className="mt-[188px] ml-[70px] w-[52px] border-t-[4px] border-solid border-white"></div>
          <div className="text-[40px] ml-[70px] mt-[30px]">
            <div className="leading-[50px]">
              <span>라카이브</span> <span className="font-bold">대시보드</span>
            </div>
            <div className="font-bold">Free Plan 권한 변경</div>
          </div>
          <div className="ml-[70px] text-[16px] font-light mt-[20px] leading-[24px]">
            <div>Free Plan을 사용하는 고객님들을 대상으로 서비스 </div>
            <div>이용정책이 변경될 예정입니다.</div>
          </div>
          <div className="ml-[70px] mt-[15px] leading-[24px] text-[16px] font-light">
            <div className="font-bold">
              “최초 로그인 후, 7일 동안 Trial Plan기준으로 서비스 이용이 가능하나 이후에는 모든 서비스 접근이 제한”
              <span className="font-light ml-[5px]">됩니다.</span>
            </div>
          </div>
        </div>
        <div className="pt-[40px]  h-full flex flex-col justify-between pb-[70px]">
          <div className="flex flex-col items-end justify-center pr-[80px]">
            <div className="text-[16px] font-light">단, 하나의 글로벌 공급망 인텔리전스</div>
            <GothamDiv className="text-[#78ECB6] text-[18px] leading-[40px]">See everything, Do anything</GothamDiv>
          </div>
          <div className="flex flex-col font-bold gap-4  place-self-end mr-[70px] w-[340px] h-[270px] border-[#fff] border-[4px] border-solid px-[20px] py-[21px]">
            <div className="text-[24px] leading-[30px]">안내사항</div>
            <div className="text-[16px] flex items-center gap-2 ">
              <span className='before:content-["•"] before:mr-1'>대상</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span>대시보드 Free Plan을 사용중인 고객</span>
            </div>
            <div className="text-[16px] flex items-center gap-2 whitespace-nowrap ">
              <span className='before:content-["•"] before:mr-1'>기간</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span>2024년 8월 19일 ~</span>
            </div>
            <div className="text-[16px] flex items-center gap-2">
              <span className='before:content-["•"] before:mr-1'>내용</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span>Free Plan 서비스 이용정책 변경</span>
            </div>
            <div className="text-[16px] flex items-center gap-2 ">
              <span className='before:content-["•"] before:mr-1'>문의</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span className="underline underline-offset-2">help@simplogis.com</span>
            </div>
            <div
              onClick={handleGoPlan}
              className="w-[260px] h-[40px] bg-white flex items-center justify-between px-5 text-black text-[14px] cursor-pointer mt-2 place-self-center"
            >
              <span>프라이싱 페이지 가기</span>
              <LineArrow />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[44px] border-[1px] border-solid border-white text-[14px] text-[#555] flex justify-between items-center px-[20px]">
        <div className="flex items-center cursor-pointer ">
          <input
            id="default-checkbox"
            type="checkbox"
            value="1"
            checked={currentCheckDay === 1}
            onClick={e => {
              if (e.currentTarget.checked) {
                setCurrentCheckDay(+e.currentTarget.value);
              } else {
                setCurrentCheckDay(null);
              }
            }}
            // onChange={() => {
            //   setCurre
            // }}
            className="w-4 h-4 text-white accent-baseColor focus:ring-black"
          />
          <label
            htmlFor="default-checkbox"
            className="text-sm font-medium text-gray-900 cursor-pointer ms-2 dark:text-gray-300"
          >
            하루동안 보지 않기
          </label>

          <input
            id="sevenday-checkbox"
            type="checkbox"
            value="7"
            checked={currentCheckDay === 7}
            onClick={e => {
              if (e.currentTarget.checked) {
                setCurrentCheckDay(+e.currentTarget.value);
              } else {
                setCurrentCheckDay(null);
              }
            }}
            // onChange={_handleCheckNoShow}
            className="w-4 h-4 ml-3 text-white accent-baseColor focus:ring-black"
          />
          <label
            htmlFor="sevenday-checkbox"
            className="text-sm font-medium text-gray-900 cursor-pointer ms-2 dark:text-gray-300"
          >
            일주일동안 보지 않기
          </label>
        </div>
        <div className="cursor-pointer" onClick={handleCloseToday}>
          닫기
        </div>
      </div>
    </div>
  );
}

const GothamDiv = styled.div`
  font-family: 'GothamMedium';
`;
