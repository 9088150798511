import { LoadingSpinnerComponent } from 'assets/image';
import { getClass } from 'util/tailwind';

interface SpinnerProps {
  height?: string;
  isAbsolute?: boolean;
  spinnerWidth?: number;
  spinnerHeight?: number;
}

export default function Spinner({
  height = 'min-h-[40vh]',
  isAbsolute = false,
  spinnerWidth = 60,
  spinnerHeight = 60,
}: SpinnerProps) {
  return (
    <div
      className={getClass(
        `flex flex-col justify-center items-center  ${height}`,
        isAbsolute ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-transparent z-10' : '',
      )}
    >
      <LoadingSpinnerComponent width={spinnerWidth} height={spinnerHeight} />
    </div>
  );
}
