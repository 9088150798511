import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Fragment, Suspense, useEffect, useState } from 'react';

import Member from 'views/Member';
import useWebMainLayout from 'components/layouts/hooks/Controller/useWebMainLayout';

import { getClass } from 'util/tailwind';

import WebNavigation from 'components/layouts/WebNavigation';
import WebHomeFooter from './WebHomeFooter';
import WebFooter from 'components/layouts/WebFooter';
import LogisticNavigationAdaptive from './LogisticNavigationAdaptive';
import styled from 'styled-components';
import CookieConsent from 'components/CookieConsent';
import { getCookie } from 'util/cookies';

// import { isNavOpen } from 'store/navigationState';
import useMemoCookies from 'hooks/Common/useMemoCookies';

import { PaymentNoticePop } from './Notice';
// import sweetAlert from 'components/Alert/sweetAlert';

const Wrapper = styled.div``;

interface WebMainLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

const NO_SHOW_RELEASE_NOTICE_COOKIE = 'release-notice-payment-pop-close';
const NO_SHOW_RELEASE_FREE_NOTICE_COOKIE = 'release-notice-payment-free-pop-close';

// const NO_SHOW_SERVICE_POP = 'service-notice-pop-20240821';

function WebMainLayout({ children }: WebMainLayoutProps) {
  const [showCookieConsented, setShowCookieConsented] = useState(false);
  const { showLoginVar, topClick, pathName } = useWebMainLayout();
  const memoCookies = useMemoCookies();

  const [showPaymentNotice, setShowPaymentNotice] = useState(false);

  useEffect(() => {
    const hasConsent = getCookie('cookie-consent-agree');

    if (hasConsent) {
      setShowCookieConsented(false);
    } else {
      setShowCookieConsented(true);
    }
  }, [setShowCookieConsented, pathName]);

  useEffect(() => {
    memoCookies.remove(NO_SHOW_RELEASE_NOTICE_COOKIE, {
      path: '/',
    });
    memoCookies.remove('congestion-apology-popup-hidden', {
      path: '/',
    });
    const notShow = memoCookies.get(NO_SHOW_RELEASE_FREE_NOTICE_COOKIE);
    if (notShow || pathName.includes('plan')) {
      setShowPaymentNotice(false);
    } else {
      setShowPaymentNotice(true);
    }
  }, [memoCookies, setShowPaymentNotice, pathName]);

  // useEffect(() => {
  //   const has = getCookie(NO_SHOW_SERVICE_POP);
  //   if (has) return;

  //   sweetAlert({
  //     icon: 'info',
  //     title: '[공지] 서비스 이용 안내',
  //     description: (
  //       <div>
  //         <div>서비스 이용 요청이 많아 속도가 느릴 수 있습니다. </div>
  //         <div>원활한 서비스 운영을 위해 작업 중이니 양해 부탁드립니다.</div>
  //         <div
  //           onClick={() => {
  //             memoCookies.set(NO_SHOW_SERVICE_POP, true, {
  //               maxAge: 24 * 60 * 60 * 1000,
  //             });
  //           }}
  //           className="flex justify-center gap-2 mt-5 cursor-pointer"
  //         >
  //           <input id={NO_SHOW_SERVICE_POP} type="checkbox" />
  //           <label className="cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP}>
  //             다시 보지 않기
  //           </label>
  //         </div>
  //       </div>
  //     ),
  //   });
  // }, [memoCookies]);

  return (
    <Wrapper className={getClass('min-w-[1600px] w-full ')}>
      <main className="min-h-[60vh] relative">
        {showPaymentNotice && (
          <PaymentNoticePop cookieName={NO_SHOW_RELEASE_FREE_NOTICE_COOKIE} close={setShowPaymentNotice} />
        )}
        <WebNavigation />
        <div className="flex">
          {pathName.includes('logistic-tools') && <LogisticNavigationAdaptive />}
          {children}
        </div>
        {showCookieConsented && <CookieConsent setShowCookieConsented={setShowCookieConsented} />}
      </main>
      {showLoginVar && (
        <Suspense fallback={<Fragment></Fragment>}>
          <Member />
        </Suspense>
      )}

      {pathName === '/' ? <WebHomeFooter /> : <WebFooter />}
      <div className="w-[50px] h-[50px] fixed bottom-[100px] right-[30px]">
        <div
          onClick={topClick}
          className="w-full h-full bg-white cursor-pointer rounded-2xl
            bg-[rgba(0,0,0,0.4)]
            opacity-20
            hover:opacity-100
            text-navyColor
            flex
            justify-center
            items-center
          "
        >
          Top
        </div>
      </div>
    </Wrapper>
  );
}

export default withRouter(WebMainLayout);
