import { useMutation } from '@apollo/client';
import {
  Mut_Send_Mail_Subscription,
  Mut_Send_Mail_SubscriptionVariables,
} from '__apollo__generated__/Mut_Send_Mail_Subscription';
import sweetAlert from 'components/Alert/sweetAlert';
import { SEND_MAIL_SUBSCRIPTION } from 'graphql/Home/MailSubscription/sendSubscriptionQuery';
import { SyntheticEvent, useState } from 'react';
import { REGEX_EMAIL } from 'util/constantRegex';
import sweetToast from 'components/Alert/sweetToast';
import { footerMenu } from '../util';
import { Link } from 'react-router-dom';

export default function WebHomeFooter() {
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState('');

  const [createSubscription, { loading }] = useMutation<
    Mut_Send_Mail_Subscription,
    Mut_Send_Mail_SubscriptionVariables
  >(SEND_MAIL_SUBSCRIPTION, {
    onCompleted: (data: Mut_Send_Mail_Subscription) => {
      const {
        createMailSubscriptionInfo: { ok, error },
      } = data;

      if (!ok || error) {
        sweetAlert({
          icon: 'error',
          title: '구독신청 실패',
          description: <div>{error}</div>,
        });
        return;
      }

      sweetToast({
        icon: 'success',
        title: '구독신청이 완료되었습니다',
      });
    },
    fetchPolicy: 'network-only',
  });

  const handleOnChangeEmail = (event: SyntheticEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    setEmail(value);
  };

  const handleBtnClick = async () => {
    if (loading) {
      return;
    }

    if (!isChecked) {
      return sweetToast({
        icon: 'warning',
        title: '이용약관에 동의해주세요',
      });
    }
    if (email.trim() === '' || !REGEX_EMAIL.test(email)) {
      return sweetToast({
        icon: 'warning',
        title: '이메일을 확인해주세요',
      });
    }

    await createSubscription({
      variables: {
        input: {
          email,
        },
      },
    });
  };

  const handleOpenChat = () => {
    window.open(
      'https://larchive.simplogis.com/community/notice/638f036b4dfa2eaa84d7c720?page=1&hash=%EC%A0%84%EC%B2%B4',
    );
  };

  return (
    <footer
      className={`w-full min-h-[720px] py-[60px]
    bg-main-page-footer bg-cover
    flex flex-col
    items-center
    text-white

  `}
    >
      <section className="w-[1400px] grid grid-cols-2 py-11 mb-[120px] px-5">
        <div className="flex flex-col gap-5">
          <h3 className="mb-[8px] text-[48px] font-medium">Larchive Weekly Report</h3>
          <p className="text-[18px] leading-6">
            매주 화요일에 발간하는
            <br />
            '라카이브 위클리 시황보고서'를 구독하시면
            <br />
            다음과 같은 정보를 받아보실 수 있습니다.
          </p>
          <div className="grid grid-cols-2 w-[500px] text-[14px]">
            <ul className="flex flex-col gap-3">
              <li>• 업계 최신정보</li>
              <li>• 물류동향/운임시황</li>
            </ul>
            <ul className="flex flex-col gap-3">
              <li>• 해외 기사 번역본</li>
              <li>• 공급망 관련 통계자료</li>
            </ul>
          </div>
        </div>
        <div>
          <div className="flex gap-2 mb-5">
            <input
              onChange={handleOnChangeEmail}
              value={email}
              className="h-[50px] w-[340px] rounded-[4px] px-4"
              placeholder="이메일 주소"
              type="email"
            />
            <button
              className="w-[140px] h-[50px] text-white border-white border-[1px] border-solid p-4 rounded-[4px]"
              type="button"
              onClick={handleBtnClick}
            >
              리포트 구독하기
            </button>
          </div>
          <div>
            <div className="flex items-center gap-3 mb-3">
              <input
                onChange={() => {
                  setIsChecked(prev => !prev);
                }}
                className="checkbox-input cursor-pointer w-[20px] aspect-square accent-baseColor"
                type="checkbox"
              />
              <p>개인정보 수집 및 이용에 동의합니다.</p>
            </div>
            <p className="text-[14px] leading-6">
              보고서 발송을 위한 이메일 정보를 수집하고 이용합니다.
              <br />
              수집된 정보는 보고서 발송 외 다른 목적으로 이용되지 않으며,
              <br />
              서비스가 종료되거나 구독을 해지할 경우 즉시 파기됩니다.
            </p>
          </div>
        </div>
      </section>
      <section className="w-[1400px] px-5">
        <div className="flex items-center justify-between mb-5 break-words border-b-[1px] border-gray-300 border-solid pb-6">
          <div className="flex gap-4">
            {footerMenu.slice(0, 3).map(link => (
              <Link className="text-white" to={{ pathname: link['url'] }} key={link['name']}>
                {link['name']}
              </Link>
            ))}
          </div>
          <div className="flex gap-4">
            {footerMenu.slice(3).map(link => (
              <Link className="text-white" to={{ pathname: link['url'] }} key={link['name']}>
                {link['name']}
              </Link>
            ))}
            <button className="text-white text-[16px]" onClick={handleOpenChat}>
              오픈채팅
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between py-6">
          <div>
            <span>심플로지스(주)</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>대표이사 오현민</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>사업자번호: 625-81-00962</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>
              통신판매신고: 제 2024-인천미추홀-0423호
            </span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>이메일: help@simplogis.com</span>
            {/*<span className='before:content-["|"] before:text-gray-300 before:mx-3 '>전화번호: 032-710-7102</span>*/}
          </div>
          <div className="flex gap-3">
            <Link to={{ pathname: 'https://blog.naver.com/larchive_simplogis' }} target="_blank">
              <div className="bg-naver-icon-gray w-[50px] aspect-square"></div>
            </Link>
            <Link target="_blank" to={{ pathname: 'https://www.youtube.com/watch?v=WR6xizJGEkw' }}>
              <div
                className="bg-youtube-icon-gray
              w-[50px] aspect-square
            "
              ></div>
            </Link>
          </div>
        </div>
      </section>
      <section className="flex justify-between items-center w-[1400px] px-5">
        <div className="flex flex-col gap-3">
          <p>본사: 인천광역시 미추홀구 인하로 100, 인하드림센터 1관 309호</p>
          <p>서울영업소: 서울특별시 마포구 마포대로 34(도화동, 도원빌딩 9층, 3호실)</p>
        </div>
        <p>모든 데이터는 저작권법의 보호를 받은바, 무단전재, 복사-배포 등을 금합니다.</p>
        <p>Copyright © simplogis. All Rights Reserved.</p>
      </section>
    </footer>
  );
}
