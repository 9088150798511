import { gql } from '@apollo/client';

export const EDIT_PROFILE_PHOTO = gql`
  mutation Mut_Edit_Profile_Photo($input: EditProfilePhotoInputDto!) {
    editProfilePhoto(input: $input) {
      error
      ok
    }
  }
`;
